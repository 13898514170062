<template>
    <el-dialog :visible="visible" :title="dialogTitle" @close="handleCancel" :close-on-click-modal="false" width="800px">
        <div v-loading="loading">
            <el-radio-group v-model="postData.Type" size="medium" style="margin-bottom: 10px;width: 100%;" @input="changeType">
                <el-radio-button label="item">商品</el-radio-button>
                <el-radio-button :disabled="form.SpecQty == 0" label="spec">规格</el-radio-button>
                <el-radio-button label="special">限时特价</el-radio-button>
                <el-radio-button :disabled="form.SpecQty == 0" label="special_spec">特殊规格</el-radio-button>
                <el-radio-button :disabled="form.SpecQty == 0 || colors.length == 0" label="color">颜色映射</el-radio-button>
            </el-radio-group>
            <el-form id="item" label-width="100px" label-position="right" v-if="postData.Type == 'item'" label-suffix="：" :inline="true">
                <el-form-item label="温馨提示" v-if="form.SpecQty > 0">
                    <div style="color: red;width:500px;">特殊颜色/尺码，请切换到【特殊规格】处理</div>
                </el-form-item>
                <el-row :gutter="0">
                    <el-col :span="16">
                        <el-form-item label="货号" prop="ItemNo">
                            <el-input v-model="form.ProPrice.ItemNo" placeholder="请输入货号" :disabled="form.ProductID > 0" style="width: 250px;" />
                        </el-form-item>
                        <el-form-item label="商品价" prop="Price">
                            <el-input v-model="form.ProPrice.Price" placeholder="请输入商品价" v-format.float style="width: 250px;" /> 元
                        </el-form-item>
                        <el-form-item label="采购价" prop="Cost">
                            <el-input v-model="form.ProPrice.Cost" placeholder="请输入采购价" v-format.float style="width: 250px;" /> 元
                        </el-form-item>
                        <el-form-item label="指定价" prop="SettlePrice" v-if="form.IncludeSettle">
                            <el-input v-model="form.ProPrice.SettlePrice" :placeholder="(form.ProPrice.Price - form.Discount)" v-format.float
                                style="width: 250px;" />
                            元
                        </el-form-item>
                        <el-form-item label="供应商">
                            <el-autocomplete placeholder="供应商" v-model="form.DefaultSupplierName" style="width:250px;"
                                :fetch-suggestions="loadSuppliers" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" style="text-align:right;">
                        <el-upload ref="uploadImage" class="avatar-uploader" accept="image/*" :action="uploadUrl" :headers="headers"
                            :show-file-list="false" :on-success="uploadImgSuccess" :on-error="uploadImgError">
                            <img v-if="form.DefaultImage" :src="form.DefaultImage" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-col>
                </el-row>
                <el-form-item label="颜色">
                    <dynamic-tags style="width:610px;" v-model="itemColors" title="添加颜色" :exten="{ visable: false }" @valueChange="colorsChange" />
                </el-form-item>
                <el-form-item label="尺码">
                    <dynamic-tags style="width:610px;" v-model="itemSizes" title="添加尺码" :exten="{ visable: true, title: '选择尺码' }"
                        @valueChange="sizesChange" />
                </el-form-item>
                <el-form-item label="货号映射">
                    <dynamic-tags style="width:610px;" v-model="itemNos" title="添加新别称" :exten="{ visable: false }" @valueChange="itemNosChange" />
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch v-model="form.IsOnSale" active-color="#13ce66" active-text="在售中" inactive-color="#ff4949" inactive-text="已下架"
                        :active-value=0 :inactive-value=1 @change="handleProductStatusChange"></el-switch>
                </el-form-item>
            </el-form>
            <el-table :data="form.Specs" :key="listKey" v-if="postData.Type == 'spec'" border :span-method="objectSpanMethod">
                <el-table-column prop="Color" label="颜色" show-overflow-tooltip align="center">
                    <template slot-scope="{ row }">
                        {{ row.Color }}
                        <br />
                        <template v-if="form.IsOnSale == 0">
                            <el-link type="success" style="margin-right:10px;" @click="handleSpecsStatusChange(null, row.Color, 0)"
                                v-if="checkSpecsColor(row.Color, 1)">全部上架</el-link>
                            <el-link type="danger" @click="handleSpecsStatusChange(null, row.Color, 1)"
                                v-if="checkSpecsColor(row.Color, 0)">全部下架</el-link>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="Size" label="尺码" show-overflow-tooltip align="center" />
                <el-table-column prop="IsOnSale" label="状态" align="center">
                    <template slot-scope="{ row }">
                        <template v-if="form.IsOnSale == 0">
                            {{ row.IsOnSale == 0 ? '在售' : '下架' }}
                        </template>
                        <template v-else>
                            下架
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="90" align="center" v-if="form.IsOnSale == 0">
                    <template slot-scope="{ row }">
                        <el-link type="success" v-if="row.IsOnSale != 0"
                            @click="handleSpecsStatusChange(row.Color + '-' + row.Size, null, 0)">上架</el-link>
                        <el-link type="danger" v-if="row.IsOnSale == 0"
                            @click="handleSpecsStatusChange(row.Color + '-' + row.Size, null, 1)">下架</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-form id="special_spec" :inline="true" :label-width="postData.Type == 'color' ? '120px' : '100px'" label-position="right"
                label-suffix="：" v-if="postData.Type != 'item' && postData.Type != 'spec'">
                <el-form-item label="温馨提示" v-if="postData.Type == 'special_spec'">
                    <span style="color: red;">无需特殊配置的价格，放空即可；特殊规格删除时【所有采购商】指定价都会删除</span>
                </el-form-item>
                <el-form-item :label="'新增' + (postData.Type == 'color' ? '颜色映射' : '价格')">
                    <el-select class="colorSelect" placeholder="选择颜色" v-model="newColor" multiple collapse-tags v-if="colors.length > 0"
                        @change="handleSelectChanged('color')">
                        <el-option v-for="item in colors" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                    <el-select class="sizeSelect" style="margin-left:3px;" placeholder="选择尺码" v-model="newSize" multiple collapse-tags
                        v-if="postData.Type != 'color' && sizes.length > 0" @change="handleSelectChanged('size')">
                        <el-option v-for="item in sizes" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                    <el-tooltip class="item" effect="dark" content="商品价" placement="bottom">
                        <el-input class="newPrice" v-model="newProPrice" v-if="postData.Type != 'color'" :placeholder="form.ProPrice.Price"
                            v-format.float />
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="采购价" placement="bottom">
                        <el-input class="newPrice" v-model="newProCost" v-if="postData.Type != 'color'" :placeholder="form.ProPrice.Cost"
                            v-format.float />
                    </el-tooltip>
                    <el-date-picker v-if="postData.Type == 'special'" v-model="timeRangeValue" type="daterange" value-format="yyyy-MM-dd"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width:200px;margin-left:3px;">
                    </el-date-picker>
                    <el-button style="margin-left:3px;margin-top: -3px;" type="primary" plain @click="addItem">新增</el-button>
                    <el-button type="danger" plain v-if="postData.Type == 'special_spec'" @click="clearItem"
                        style="float:right;margin-left:5px;">清空全部</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="proSpecialPrices" :key="listKey" v-if="postData.Type == 'special'" :cell-class-name="handleCellClassName"
                :row-class-name="handleRowClassName">
                <el-table-column prop="Color" label="颜色" width="120" show-overflow-tooltip align="center" v-if="colors.length > 0" />
                <!--价格配置Start-->
                <el-table-column prop="Size" label="尺码" width="100" show-overflow-tooltip align="center" v-if="sizes.length > 0" />
                <el-table-column prop="Price" label="商品价" align="center">
                    <template slot-scope="{ row }">
                        <el-input v-model="row.Price" :placeholder="form.ProPrice.Price" style="width:70px;" v-format.float /> 元
                    </template>
                </el-table-column>
                <el-table-column prop="Cost" label="采购价" align="center">
                    <template slot-scope="{ row }">
                        <el-input v-model="row.Cost" style="width:70px;" :placeholder="form.ProPrice.Cost" v-format.float />
                        元
                    </template>
                </el-table-column>
                <el-table-column prop="TimeRange" label="限时" width="230" align="center">
                    <template slot-scope="{ row }">
                        {{ row.TimeRange ? row.TimeRange : '-' }}
                    </template>
                </el-table-column>
                <!--价格配置End-->
                <el-table-column label="操作" width="90" align="center">
                    <template slot-scope="{ row, $index }">
                        <el-button type="text" icon="el-icon-delete" style="color:red;" @click="delItem(row, $index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="form.Prices" :key="listKey" v-if="form.SpecQty > 0 && postData.Type == 'special_spec'"
                :cell-class-name="handleCellClassName" :row-class-name="handleRowClassName">
                <el-table-column prop="Color" label="颜色" width="120" show-overflow-tooltip align="center" v-if="colors.length > 0" />
                <!--价格配置Start-->
                <el-table-column prop="Size" label="尺码" width="100" show-overflow-tooltip align="center" v-if="sizes.length > 0" />
                <el-table-column prop="Price" label="商品价" align="center">
                    <template slot-scope="{ row }">
                        <el-input v-model="row.Price" :placeholder="form.ProPrice.Price" style="width:70px;" v-format.float /> 元
                    </template>
                </el-table-column>
                <el-table-column prop="SettlePrice" label="指定价" width="180" v-if="form.IncludeSettle" align="center">
                    <template slot-scope="{ row }">
                        <el-select v-model="row.SettleType" style="width: 85px;">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <el-input v-model="row.SettlePrice" :placeholder="row.Price - form.Discount" v-format.float
                            style="width: 50px;margin-left:3px;" /> 元
                    </template>
                </el-table-column>
                <el-table-column prop="Cost" label="采购价" align="center">
                    <template slot-scope="{ row }">
                        <el-input v-model="row.Cost" style="width:70px;" :placeholder="form.ProPrice.Cost" v-format.float />
                        元
                    </template>
                </el-table-column>
                <!--价格配置End-->
                <el-table-column label="操作" width="90" align="center">
                    <template slot-scope="{ row, $index }">
                        <el-button type="text" icon="el-icon-delete" style="color:red;" @click="delItem(row, $index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="form.ColorMappings" :key="listKey" v-if="form.SpecQty > 0 && postData.Type == 'color'"
                :cell-class-name="handleCellClassName" :row-class-name="handleRowClassName">
                <el-table-column prop="Color" label="特殊颜色" width="120" show-overflow-tooltip align="center" v-if="colors.length > 0" />
                <!--颜色映射Start-->
                <el-table-column label="别称">
                    <template slot-scope="{ row,$index }">
                        <el-tag v-for="(rename, index) in row.RenameList" :key="index" closable type="warning" @close="delRename(row, index)">
                            {{ rename }}
                        </el-tag>
                        <el-input class="input-new-name" v-if="editIndex == $index" v-model.trim="newRename" ref="renameInput" size="small"
                            @change="addRename(row)" @blur="addRename(row)">
                        </el-input>
                        <el-button v-else class="button-new-name" size="small" @click="editIndex = $index">添加新别称</el-button>
                    </template>
                </el-table-column>
                <!--颜色映射End-->
                <el-table-column label="操作" width="100" align="center">
                    <template slot-scope="{ row, $index }">
                        <el-button type="text" icon="el-icon-delete" style="color:red;" @click="delItem(row, $index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取 消</el-button>
            <el-button type="primary" @click="handleSave" v-loading="loading">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
import DynamicTags from './Tags.vue'
import { baseURL, successCode } from '@/config';
export default {
    name: 'ProductSetting',
    components: {
        DynamicTags
    },
    data() {
        return {
            loading: false,
            postData: {},
            uploadUrl:
                baseURL + "/api/base/file/uploadImg?path=product",
            headers: {
                Authorization: "Bearer " + this.$store.getters["user/accessToken"],
            },
            form: {
                SpecQty: 2,
                ProductID: 0,
                PurchaserID: '',
                PurchaserName: '',
                IncludeSettle: false,
                Colors: '',
                Sizes: '',
                IsOnSale: 0,
                ProPrice: {},
                Prices: [],
                ColorMappings: [],
                Specs: [],
                DefaultImage: '',
                DefaultSupplierName: '',
                DefaultSupplier: 0,
                ItemNoMapping: {
                    Renames: ''
                },
                Discount: 0
            },
            proSpecialPrices: [],
            options: [
                { value: 0, label: '固定价' },
                { value: 1, label: '便宜' },
            ],
            itemColors: [],
            itemSizes: [],
            listKey: '',
            visible: false,
            dialogTitle: '编辑',
            newColor: [],
            newSize: [],
            newProPrice: null,
            newProCost: null,
            editIndex: -1,
            newRename: '',
            timeRangeValue: [],
            itemNos: [],
            mergeObj: {}, // 用来记录需要合并行的下标
            mergeArr: ["Color"], // 表格中的列名
            suppliers: [],
        };
    },
    computed: {
        colors() {
            if (this.form.Colors) {
                let list = this.form.Colors.split(',').sort();
                if (this.form.SpecQty > 1 && this.postData.Type != 'color') {
                    list.unshift('全部');
                }
                return list;
            }
            return [];
        },
        sizes() {
            if (this.form.Sizes) {
                let list = this.form.Sizes.split(',').sort();
                if (this.form.SpecQty > 1) {
                    list.unshift('全部');
                }
                return list;
            }
            return [];
        },
    },
    mounted() {
        this.$nextTick(async () => {
            this.fetchSuppliers();
        });
    },
    methods: {
        async fetchSuppliers() {
            const { data } = await submit('/api/purchase/supplier/getList', {
                CurrentPage: 1,
                PageSize: 10000,
            });
            data.datas.forEach(element => {
                element.value = element.SupplierName
            });
            this.suppliers = data.datas || [];
        },
        createFilter(qs) {
            return (supplier) => {
                return supplier.value.toLowerCase().includes(qs.toLowerCase());
            };
        },
        async loadSuppliers(qs, cb) {
            const suppliers = this.suppliers;
            var results = qs ? suppliers.filter(this.createFilter(qs)) : suppliers;
            cb(results || []);
        },
        uploadImgSuccess(response) {
            if (successCode.includes(response.code)) {
                this.form.DefaultImage = response.data;
            } else {
                this.$message({
                    type: 'error',
                    message: response.msg
                });
            }
        },
        uploadImgError() {
            this.$message({
                type: 'error',
                message: '上传失败'
            });
        },
        handleProductStatusChange(newVal) {
            var that = this;
            if (newVal == 0) {
                that.$confirm(`确定要重新上架货品：${that.form.ProPrice.ItemNo}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.form.IsOnSale = 0;
                    that.form.Specs.forEach((item, index) => {
                        item.IsOnSale = 0;
                    });
                }).catch(() => {
                    that.$nextTick(() => {
                        that.form.IsOnSale = 1;
                    });
                });
            } else {
                that.$confirm(`确定要下架货品：${that.form.ProPrice.ItemNo}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.form.IsOnSale = 1;
                }).catch(() => {
                    that.$nextTick(() => {
                        that.form.IsOnSale = 0;
                    });
                });
            }
        },
        handleSpecsStatusChange(specName, color, isOnSale) {
            this.form.Specs.forEach((item, index) => {
                if (specName != null && item.SpecName == specName) {
                    item.IsOnSale = isOnSale;
                } else if (color != null && item.Color == color) {
                    item.IsOnSale = isOnSale;
                }
            });
        },
        checkSpecsColor(color, isOnSale) {
            return this.form.Specs.some(x => x.Color == color && x.IsOnSale == isOnSale);
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (column.property == 'Color') {
                if (this.mergeArr[rowIndex]) {
                    return [this.mergeArr[rowIndex], 1];
                } else {
                    return [0, 0];
                }
            }
        },
        getSpanArr() {
            let that = this;
            let itemIndex = 0;
            that.mergeArr = [];
            that.form.Specs.forEach((item, index) => {
                if (index === 0 || (index > 0 && item.Color != that.form.Specs[index - 1].Color)) {
                    itemIndex = index;
                    that.mergeArr.push(1);
                } else {
                    that.mergeArr[itemIndex] += 1;
                    that.mergeArr.push(0);
                }
            });
        },
        generateSpecs() {
            if (this.form.Colors && this.form.Sizes) {
                this.form.SpecQty = 2;
            }
            else if (this.form.Colors || this.form.Sizes) {
                this.form.SpecQty = 1;
            }
            else {
                this.form.SpecQty = 0;
            }
            var that = this;
            var list = [];
            var colorList = that.form.Colors.split(',').sort();
            var sizeList = that.form.Sizes.split(',').sort();
            colorList.forEach(x => {
                sizeList.forEach(y => {
                    var filters = that.form.Specs.filter(t => t.Color == x && t.Size == y);
                    if (filters.length > 0) {
                        list.push(filters[0]);
                    } else {
                        list.push({
                            ProductID: that.form.ProductID,
                            SpecName: x + "-" + y,
                            Color: x,
                            Size: y,
                            IsOnSale: that.form.IsOnSale,
                        });
                    }
                });
            });
            that.form.Specs = list;
            that.getSpanArr();
        },
        colorsChange() {
            this.form.Colors = this.itemColors.join(',');
            this.generateSpecs();
        },
        sizesChange() {
            this.itemSizes = this.itemSizes.sort(function (a, b) {
                if (a !== b) return a < b ? -1 : 1
                return 0;
            });
            this.form.Sizes = this.itemSizes.join(',');
            this.generateSpecs();
        },
        handleSelectChanged(type) {
            if (this.postData.Type != 'color') {
                var dataList = (type == 'color' ? this.colors : this.sizes).filter(x => x != '全部');
                var valueList = type == 'color' ? this.newColor : this.newSize;
                var hasAll = valueList.some(x => x == '全部');
                if (hasAll || (!hasAll && valueList.length == dataList.length)) {
                    this.$nextTick(() => {
                        if (type == 'color') {
                            this.newColor = ['全部'];
                        } else {
                            this.newSize = ['全部'];
                        }
                    });
                }
            }
        },
        itemNosChange() {
            this.form.ItemNoMapping.Renames = this.itemNos.join(',');
        },
        async open(postData) {
            var that = this;
            that.handleCancel();
            that.postData = postData;
            that.dialogTitle = `新增商品`;
            if (postData.ProductID > 0) {
                that.dialogTitle = `商品编辑`;
                let { data } = await submit("/api/product/getProductSetting", this.postData);
                that.form = data;
            }
            else {
                that.form.SpecQty = 0;
            }
            that.form.TradeIDs = postData.TradeIDs ? postData.TradeIDs : '';
            that.form.ColorMappings.forEach(color => {
                color.RenameList = color.Renames.split(',');
                if (!color.RenameList[0]) {
                    color.RenameList.splice(0, 1);
                }
            });
            if (typeof (that.form.ItemNoMapping) != 'undefined' && that.form.ItemNoMapping.Renames != '') {
                that.itemNos = that.form.ItemNoMapping.Renames.split(',');
            }
            if (that.form.ProPrice.ID == 0) {
                that.form.ProPrice.SettlePrice = that.form.ProPrice.Price - that.form.Discount;
            }
            that.form.Prices.forEach(price => {
                if (price.PriceID == 0) {
                    price.Price = that.form.ProPrice.Price;
                    price.Cost = that.form.ProPrice.Cost;
                }
                if (price.ID == 0) {
                    price.SettlePrice = price.Price - that.form.Discount;
                }
            });
            that.proSpecialPrices = that.form.Prices.filter(x => (x.TimeRange ?? '') != '');
            that.form.Prices = that.form.Prices.filter(x => (x.TimeRange ?? '') == '');
            that.itemColors = that.form.Colors ? that.form.Colors.split(',') : [];
            that.itemSizes = that.form.Sizes ? that.form.Sizes.split(',') : [];
            that.getSpanArr();
            that.changeType();
            that.visible = true;
        },
        async changeType() {
            let title = this.postData.ProductID > 0 ? '编辑商品' : '新增商品';
            switch (this.postData.Type) {
                case "item":
                    this.dialogTitle = title;
                    break;
                case "spec":
                    this.dialogTitle = `${title}-规格`;
                    break;
                case "special":
                    this.dialogTitle = `${title}-限时特价`;
                    break;
                case "special_spec":
                    this.dialogTitle = `${title}-特殊规格`;
                    break;
                case "color":
                    this.dialogTitle = `${title}-颜色映射`;
                    break;
            }
            this.listKey = Math.random();
            this.newColor = [];
            this.newSize = [];
            this.newProPrice = null;
            this.newProCost = null;
            this.timeRangeValue = [];
        },
        handleRowClassName({ row }) {
            if (row.IsDelete) {
                return 'hidden';
            }
            return "";
        },
        handleCellClassName({ row, column }) {
            if (["Color", "Size"].includes(column.property)) {
                return "mark";
            }
            return "";
        },
        async addItem() {
            let addData = {
                ProductID: this.form.ProductID
            }
            let colorList = [];
            let sizeList = [];
            let existsList = [];
            let successCount = 0;
            switch (this.postData.Type) {
                case "special":
                    if (this.timeRangeValue.length <= 0) {
                        this.$baseMessage("限时特价时间范围不能为空", "error");
                        return;
                    }
                    colorList = this.newColor.length <= 0 ? ["全部"] : this.newColor;
                    sizeList = this.newSize.length <= 0 ? ["全部"] : this.newSize;
                    addData.Price = this.newProPrice == null ? '' : this.newProPrice;
                    addData.Cost = this.newProCost == null ? '' : this.newProCost;
                    addData.SettlePrice = '';
                    addData.TimeRange = this.timeRangeValue.join('至');
                    colorList.forEach(x => {
                        sizeList.forEach(y => {
                            if (this.proSpecialPrices.filter(t => t.Color == x && t.Size == y && t.TimeRange == addData.TimeRange).length > 0) {
                                existsList.push(x + '-' + y + '-' + addData.TimeRange);
                            } else {
                                let item = { ...addData };
                                item.Color = x;
                                item.Size = y;
                                this.proSpecialPrices.push(item);
                                successCount++;
                            }
                        });
                    });
                    break;
                case "special_spec":
                    if (this.form.SpecQty == 1) {
                        if ((this.colors.length > 0 && this.newColor.length <= 0) || (this.sizes.length > 0 && this.newSize.length <= 0)) {
                            this.$baseMessage(`请选择${(this.colors.length > 0 ? '颜色' : '尺码')}`, "error");
                            return;
                        }
                    }
                    else {
                        if ((this.newColor.length <= 0 || this.newColor.some(x => x == '全部')) && (this.newSize.length <= 0 || this.newSize.some(x => x == '全部'))) {
                            this.$baseMessage("颜色尺码至少选择一个", "error");
                            return;
                        }
                    }
                    addData.Price = this.newProPrice == null ? '' : this.newProPrice;
                    addData.Cost = this.newProCost == null ? '' : this.newProCost;
                    addData.SettlePrice = '';
                    if (this.form.IncludeSettle) {
                        addData.PurchaserID = this.postData.PurchaserID;
                        addData.PurchaserName = this.postData.PurchaserName;
                    }
                    colorList = this.newColor.length <= 0 ? ["全部"] : this.newColor;
                    sizeList = this.newSize.length <= 0 ? ["全部"] : this.newSize;
                    colorList.forEach(x => {
                        sizeList.forEach(y => {
                            if (this.form.Prices.filter(t => t.Color == x && t.Size == y).length > 0) {
                                existsList.push(x + '-' + y);
                            } else {
                                let item = { ...addData };
                                item.Color = x;
                                item.Size = y;
                                this.form.Prices.push(item);
                                successCount++;
                            }
                        });
                    });
                    break;
                case "color":
                    if (this.newColor.length <= 0) {
                        this.$baseMessage("请选择颜色", "error");
                        return;
                    }
                    this.newColor.forEach(x => {
                        if (this.form.ColorMappings.filter(t => t.Color == x).length > 0) {
                            existsList.push(x);
                        } else {
                            let item = { ...addData };
                            item.Color = x;
                            item.Renames = '';
                            item.RenameList = [];
                            this.form.ColorMappings.push(item);
                            successCount++;
                        }
                    });
                    break;
            }
            if (existsList.length > 0) {
                if (successCount > 0) {
                    this.$baseMessage("跳过已存在的配置【" + existsList.join('、') + "】", "warning");
                } else {
                    this.$baseMessage("已存在的配置【" + existsList.join('、') + "】，不可重复添加", "error");
                }
            }
            this.timeRangeValue = [];
            this.newSize = [];
            this.newColor = [];
        },
        clearItem() {
            switch (this.postData.Type) {
                case "special":
                    if (this.proSpecialPrices.length == 0) {
                        return;
                    }
                    for (let index = this.proSpecialPrices.length - 1; index >= 0; index--) {
                        this.delItem(this.proSpecialPrices[index], index);
                    }
                    break;
                case "special_spec":
                    if (this.form.Prices.length == 0) {
                        return;
                    }
                    for (let index = this.form.Prices.length; index >= 0; index--) {
                        this.delItem(this.form.Prices[index], index);
                    }
                    break;
            }
        },
        async delItem(row, index) {
            switch (this.postData.Type) {
                case 'special':
                    if (row.PriceID > 0) {
                        this.proSpecialPrices[index].IsDelete = true;
                    }
                    else {
                        this.proSpecialPrices.splice(index, 1);
                    }
                    break;
                case 'special_spec':
                    if (row.PriceID > 0 || row.ID > 0) {
                        this.form.Prices[index].IsDelete = true;
                    }
                    else {
                        this.form.Prices.splice(index, 1);
                    }
                    break;
                case 'color':
                    if (row.ID > 0) {
                        this.form.ColorMappings[index].IsDelete = true;
                    }
                    else {
                        this.form.ColorMappings.splice(index, 1);
                    }
                    break;
            }
            this.listKey = Math.random();
        },
        addRename(row) {
            if (this.newRename == '') {
                return;
            }
            row.RenameList.push(this.newRename);
            row.Renames = row.RenameList.join(',');
            this.newRename = '';
            this.editIndex = -1;
        },
        delRename(row, rIndex) {
            row.RenameList.splice(rIndex, 1);
            row.Renames = row.RenameList.join(',');
            this.listKey = Math.random();
        },
        handleCancel() {
            this.visible = false;
            this.postData = {};
            this.form = {
                SpecQty: 2,
                ProductID: 0,
                PurchaserID: '',
                PurchaserName: '',
                IncludeSettle: false,
                Colors: '',
                Sizes: '',
                IsOnSale: 0,
                ProPrice: {},
                Prices: [],
                ColorMappings: [],
                Specs: [],
                DefaultImage: '',
                DefaultSupplierName: '',
                DefaultSupplier: 0,
                ItemNoMapping: {
                    Renames: ''
                },
                Discount: 0
            };
            this.newColor = '';
            this.newSize = '';
            this.editIndex = -1;
            this.newRename = '';
            this.itemNos = [];
        },
        async handleSave() {
            this.loading = true;
            var data = JSON.parse(JSON.stringify(this.form));
            if (typeof (data.ProPrice.ItemNo) == 'undefined' || data.ProPrice.ItemNo == '') {
                this.loading = false;
                this.$baseMessage("货号不能为空", "error");
                return;
            }
            if (typeof (data.ProPrice.Price) == 'undefined' || data.ProPrice.Price == '') {
                this.loading = false;
                this.$baseMessage("商品价不能为空", "error");
                return;
            }
            let price = Number(data.ProPrice.Price);
            if (price <= 0) {
                this.loading = false;
                this.$baseMessage("商品价不能小于0", "error");
                return;
            }
            if (data.ProPrice.SettlePrice == '') {
                data.ProPrice.IsDelete = true;
            }
            else if (data.ProPrice.SettlePrice <= 0) {
                this.loading = false;
                this.$baseMessage("商品指定价不能小于0", "error");
                return;
            }
            let errors = [];
            this.proSpecialPrices.forEach(price => {
                if (price.Price == '') {
                    price.Price = data.ProPrice.Price;
                }
                if (price.Cost == '') {
                    price.Cost = data.ProPrice.Cost;
                }
                if (price.SettlePrice == '') {
                    price.SettlePrice = price.Price - data.Discount;
                }
                if (price.Price <= 0) {
                    errors.push(`商品限时特价${price.Color}-${price.Size}商品价不能小于0`);
                }
                if (price.SettlePrice <= 0) {
                    errors.push(`商品限时特价${price.Color}-${price.Size}商品指定价不能小于0`);
                }
            });
            data.Prices.forEach(price => {
                if (price.Price == '') {
                    price.Price = data.ProPrice.Price;
                }
                if (price.Cost == '') {
                    price.Cost = data.ProPrice.Cost;
                }
                if (price.SettlePrice == '') {
                    price.SettlePrice = price.Price - data.Discount;
                }
                if (price.Price <= 0) {
                    errors.push(`${price.Color}-${price.Size}商品价不能小于0`);
                }
                if (price.SettlePrice <= 0) {
                    errors.push(`${price.Color}-${price.Size}商品指定价不能小于0`);
                }
            });
            if (errors.length > 0) {
                this.loading = false;
                this.$baseMessage(errors.join('\r\n'), "error");
                return;
            }
            data.Prices = [...this.proSpecialPrices, ...data.Prices];
            let rsp = await submit("/api/product/saveProductSetting", data);
            this.loading = false;
            if (!rsp.data.success) {
                this.$baseMessage(rsp.data.msg, "error");
                return;
            }
            this.$emit('handleSearch');
            this.handleCancel();
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep {
    .el-dialog {
        max-height: 750px !important;
    }

    .el-dialog__body {
        max-height: 600px !important;
    }

    .el-dialog__body .el-form-item .el-input {
        max-width: inherit !important;
    }

    .el-checkbox__label {
        padding-left: 5px;
    }

    .el-radio-button {
        width: 20%;

        .el-radio-button__inner {
            width: 100%;
        }
    }

    .el-form-item__error {
        display: contents;
    }

    .el-table__row {
        font-size: 14px;

        .mark {
            &>div {
                color: #409EFF !important;
            }
        }

        &.hidden {
            display: none;
        }

        .el-table__cell {
            padding: 5px 0;
            vertical-align: middle;
        }
    }

    .el-tag {
        font-size: 14px;
        min-height: 30px;
        height: auto;
        line-height: 30px;
        margin: 2px !important;

        vertical-align: middle;
    }

    .el-select {
        width: 120px;
    }

    .el-range-editor.el-input__inner {
        padding: 2px 10px;
    }

    .el-date-editor .el-range-input {
        width: 72px;
    }
}

#special_spec {
    ::v-deep {
        .el-tag {
            min-height: 25px;
            line-height: 25px;
        }
    }
}

.input-new-name {
    width: 100px;

    ::v-deep {
        .el-input__inner {
            height: 35px !important;
            padding-left: 5px;
        }
    }
}

.button-new-name {
    line-height: 35px;
    padding-top: 0;
    padding-bottom: 0;
}

.colorSelect,
.sizeSelect {
    ::v-deep .el-tag {
        &:nth-last-child(2):first-child {
            max-width: 45px;
        }

        margin:1px 0px 1px 1px !important;
    }
}

.newPrice {
    width: 55px;
    margin-left: 3px;

    ::v-deep {
        .el-input__inner {
            text-align: center;
            padding: 0 5px;
        }
    }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;

    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar {
    width: auto;
    max-height: 150px;
    display: block;
}
</style>